<!--
 * 
 *邀请三期 
-->

<template>
  <div>
    <div id="invite-activity">
      <!-- 固定顶部 -->
      <div class="fixedBox" :class="webBox ? 'appFixedBox' : !isApp ? 'appFixedTop' : ''">
        <!-- tabs -->
        <div class="tabs">
          <div
            v-for="item in tabs"
            :key="item.value"
            :class="item.value == activeTab ? 'activeTab' : ''"
            @click="inviteTabs(item.value)"
          >
            {{ item.name }}

            <div v-if="item.value == activeTab" class="tabLine"></div>
          </div>
        </div>
        <!-- 我的排名 -->
        <div v-if="activeTab == 2" class="myRankBox">
          <div class="myRank">
            <div class="myNumber">
              {{ myTop.rank_num }}
            </div>
            <div class="infoBox">
              <div class="myuserInfo">
                <div class="name">
                  <div>我</div>
                </div>
                <div>
                  <span class="inviteNum">{{ myTop.total_num }}</span
                  >收藏值
                </div>
              </div>
              <div class="rewardTip">
                {{ myTop.awards }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 活动信息 -->
      <div v-if="activeTab == 1" class="activityBox" :class="!isApp ? 'appTop' : ''">
        <!-- <div style="position: relative">
          <img :src="activityStatus == 1 ? acitveIng : activeOver" alt="" />
        </div> -->
        <img src="https://activity-resource.321mh.com/nft/sgsd/invite/collectActivity2.png" />
      </div>

      <!-- 邀请榜单 -->
      <div
        v-if="activeTab == 2"
        class="rankBox"
        :class="!isApp ? (myTop.awards ? 'appRankTopReward' : 'appRankTop') : ''"
      >
        <div class="rankList">
          <div class="rankItem" v-for="(item, index) in topList" :key="index">
            <div class="userInfo">
              <div class="number" :style="index < 3 ? 'background: #3CDBE8;color:black;' : ''">
                {{ index + 1 }}
              </div>
              <div class="name">
                <div :style="index < 3 ? 'color:#3CDBE8' : ''">{{ item.user_name }}</div>
              </div>
            </div>

            <div>
              <span class="inviteNum">{{ item.total_num }}</span
              >收藏值
            </div>
          </div>

          <div class="rankTip">榜单仅显示前10名</div>
        </div>
      </div>

      <div v-if="activeTab == 3" class="myData" :class="!isApp ? 'appTop' : ''">
        <div style="margin-top: 10px">
          <div class="myDataBg">
            <img
              src="https://activity-resource.321mh.com/nft/notice/icon_bg_nljl_card2x.png"
              alt=""
              srcset=""
            />
            <div class="info">
              <div class="myTotal">
                我的总收藏值：<span>{{ myData ? myData.total_num : '-' }}</span>
              </div>
              <div class="tips">持有《盖世帝尊》第二期学院数字藏品获得收藏值：</div>
              <div class="collectNum">
                星辰学院：<span>{{ myData ? myData.num1 : '-' }}</span
                >收藏值
              </div>
              <div class="collectNum">
                龙 院：<span>{{ myData ? myData.num2 : '-' }}</span
                >收藏值
              </div>
            </div>
          </div>
        </div>
      </div>

      <PageLoading :show="pageLoading" />
    </div>
  </div>
</template>

<script>
import { ref, onMounted, reactive, toRefs, inject } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { collectrank, getusercollectdata } from '@/service/invite';
import { nftUtils } from '@/utils';
import moment from 'moment';
import PageLoading from '@/components/loading/PageLoading.vue';
export default {
  name: 'invite5',
  components: {
    PageLoading
  },
  watch: {
    '$store.state.webBox'(newVal, oldVal) {
      if (newVal != oldVal) {
        this.webBox = newVal;
      }
    }
  },
  setup() {
    const toastFn = inject('$toast');
    const store = useStore();
    const router = useRouter();
    const state = reactive({
      pageLoading: false,
      posterPop: false, // 弹窗显示
      activityId: 17,
      webBox: store.state.webBox,
      acitveIng: 'https://activity-resource.321mh.com/nft/notice/invitation_start_9-5.png', //活动中图片头部
      activeOver: 'https://activity-resource.321mh.com/nft/notice/invitation_end_9-5.png', //活动结束头图
      tabs: [
        {
          value: 1,
          name: '活动信息'
        },
        {
          value: 2,
          name: '收藏值榜单'
        },
        {
          value: 3,
          name: '收藏值记录'
        }
      ],
      activeTab: 1,
      popShow: false,
      topList: [],
      myTop: {},
      myTopItem: null,
      startTime: '',
      getMoneyPop: false,
      labelWidth: 40,
      list: [],
      finished: false,
      page: 1,
      loading: true,
      dest_account: '',
      dest_name: '',
      activityStatus: 1,
      shareText: '',
      idName: null,
      carveupStatus: null,
      carveupPrice: null,
      isApp: nftUtils.isInApp(),
      isLogin: nftUtils.isLogin(),
      labelBankWidth: 75,
      withdrawType: 1,
      userInviteInfo: {}, // 底部邀请文案情况数据
      isLoading: false,
      isLoadError: false,

      myData: {}
    });

    // 去榜单里面找出我的排名，如果不再榜单内则显示 -
    const myTopNum = id => {
      let myTopNum = '-';
      for (let i in state.topList) {
        if (state.topList[i].user_id === id) {
          myTopNum = ++i;
        }
      }
      return myTopNum;
    };

    const inviteTabs = value => {
      state.activeTab = value;
      if (value == 1) {
        nftUtils.setcnzzRecordByBtn('活动信息');
      } else if (value == 2) {
        nftUtils.setcnzzRecordByBtn('邀请榜单');
      } else if (value == 3) {
        nftUtils.setcnzzRecordByBtn('邀请记录');
      }
    };

    // 获取榜单列表
    const getTopFn = () => {
      collectrank({}).then(res => {
        console.log(res);
        if (res.status === 0) {
          state.topList = res.data.rank;
          state.myTop = res.data.user;
        }
      });
    };

    //获取用户的收藏值
    const getMyCollect = () => {
      getusercollectdata({}).then(res => {
        console.log(res);
        state.myData = res.data;
      });
    };

    // 获取格式时间
    const getTime = (time, format = 'YYYY-MM-DD HH:mm:ss') => {
      return moment(time).format(format);
    };

    // 计算与当前时间时间差(没用到)
    const diffTime = time => {
      return moment(moment(time)).diff(moment(), 'millisecond');
    };

    const toAwardList = id => {
      router.push({
        path: '/announcement/detail',
        query: { id }
      });
      nftUtils.setcnzzRecordByBtn('获奖名单');
    };

    onMounted(async () => {
      if (state.isLogin) {
      }
      getTopFn();

      getMyCollect();

      nftUtils.setcnzzRecordByBtn('进入收藏活动页面');
    });

    return {
      ...toRefs(state),
      myTopNum,
      inviteTabs,
      getTopFn,
      getTime,
      diffTime,
      toAwardList
    };
  }
};
</script>

<style lang="scss" scoped>
@import '../../styles/mixin.scss';
* {
  touch-action: pan-y;
}
#invite-activity {
  // background: #05082f;

  .myData {
    padding: 0 16px;
    padding-top: 54px;

    .myDataBg {
      position: relative;

      img {
        width: 100%;
      }

      .info {
        position: absolute;
        top: 32px;
        left: 20px;
      }
    }

    span {
      color: #3cdbe8;
    }

    .myTotal {
      font-size: 14px;
      font-weight: 600;
      color: #ffffff;
      margin-bottom: 30px;

      span {
        font-size: 20px;
      }
    }

    .tips {
      font-size: 12px;
      font-weight: 500;
      color: #ffffff;
      margin-bottom: 10px;
    }

    .collectNum {
      font-size: 12px;
      font-weight: 400;
      color: #ffffff;
      margin-bottom: 10px;
    }
  }

  .space-div {
    height: 130px;
    width: 100%;
  }
  .fixedBox {
    position: fixed;
    z-index: 10;
    background: linear-gradient(180deg, #272827 0%, #0c0d0c 49%, #1d221e 100%);
    width: 100%;
    top: 0;
    left: 0;
  }

  .appFixedTop {
    padding-top: 30px;
  }

  .appFixedBox {
    padding-top: 80px;
  }

  .tabs {
    display: flex;
    justify-content: center;
    line-height: 40px;
    padding: 7px 0;
    font-size: 14px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.7);

    .activeTab {
      color: white;
      font-size: 16px;
      font-weight: 500;
    }

    div {
      padding: 0 18px;
      position: relative;

      .tabLine {
        height: 3px;
        background: linear-gradient(132deg, #8ee4c8 0%, #30daec 100%);
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 4px;
      }
    }
  }

  .activityBox {
    padding: 54px 0 0 0;
    text-align: center;
    color: #ffffff;
    font-size: 0px;

    img {
      width: 100%;
    }

    .allActivity {
      position: fixed;
      right: 0;
      top: 100px;
      width: 71px;
      height: 28px;
      background: #58dedd;
      font-size: 12px;
      font-weight: 500;
      color: #000000;
      line-height: 28px;
      border-radius: 14px 0 0 14px;
    }

    .allActivityTop {
      top: 170px;
    }

    .winnerBtn {
      top: 140px;
    }
    .winnerBtn2 {
      top: 180px;
    }
    .winnerBtn2Top {
      top: 250px;
    }

    .winnerBtnTop {
      top: 210px;
    }
  }

  .rankBox {
    padding: 119px 20px 0 20px;
    // background: #000000;
  }

  .rankList {
    padding-bottom: 130px;

    .rankTip {
      text-align: center;
      padding: 25px 0 0 0;
      font-size: 12px;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.7);
    }
  }

  .rankItem {
    margin-top: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    font-weight: 400;

    .userInfo {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .number {
      color: rgba(255, 255, 255, 1);
      width: 28px;
      height: 28px;
      background: rgb(54, 51, 64);
      font-size: 12px;
      font-weight: 600;
      text-align: center;
      line-height: 28px;
      border-radius: 50%;
    }

    .headImg {
      margin-left: 20px;

      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 2px solid #3cdbe8;
        object-fit: cover;
      }
    }

    .name {
      margin-left: 16px;

      :first-child {
        font-size: 14px;
        font-weight: 600;
      }

      :last-child {
        color: rgba(255, 255, 255, 0.7);
      }
    }

    .inviteNum {
      color: #3cdbe8;
    }
  }

  .myRankBox {
    background: #2f293f;
    padding: 10px 20px;
    font-size: 12px;
    font-weight: 400;

    .myRank {
      margin-top: 0;
      display: flex;
      justify-content: flex-start;
    }

    .rewardTip {
      width: 100%;
      white-space: nowrap;
      font-size: 11px;
      transform: scale(0.75);
      letter-spacing: -1px;
      transform-origin: left;
      font-weight: 400;
      color: #ffffff;
    }

    .infoBox {
      width: 100%;
    }

    .myuserInfo {
      width: 290px;
      display: flex;
      justify-content: space-between;
      line-height: 32px;
      align-items: center;
    }

    .myNumber {
      width: 32px;
      height: 32px;
      margin-right: 15px;
      line-height: 32px;
      background: #fff;
      color: #05082f;
      font-size: 12px;
      font-weight: 600;
      text-align: center;
      border-radius: 50%;
      flex: none;
    }

    .inviteNum {
      color: #3cdbe8;
    }
  }

  .appTop {
    padding-top: 84px;
  }

  .appRankTop {
    padding-top: 132px;
  }

  .appRankTopReward {
    padding-top: 149px;
  }
}
</style>
<style lang="scss">
#invite-activity {
  .vantPop {
    background: transparent !important;
  }

  .van-field__label,
  .van-field__value,
  .van-field__control {
    color: white;
  }
}
</style>
